import './styles/cookie.scss';

$(function () {
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	let user_accept_cookies = '';
	let name = 'user_accept_cookies';
	//console.log(ca);
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			user_accept_cookies = c.substring(name.length, c.length);
		}
	}
	let element = document.getElementById('nety_cookie');

	if (user_accept_cookies == '' || user_accept_cookies == 0) {
		element.classList.remove('closed');
		element.classList.remove('d-none');
		element.classList.add('opened');
	} else {
		element.classList.add('closed');
		element.classList.add('d-none');
	}
	document.getElementById('nety_close_button').addEventListener('click', function (e) {
		e.preventDefault();
		let element = document.getElementById('nety_cookie');
		element.classList.add('closed');
		let date, expires;
		let days = 7;
		date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = '; expires=' + date.toUTCString();
		document.cookie = 'user_accept_cookies=0; path=/' + expires;
	}, false);
})